import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthGatewayService } from './auth-gateway.service';
import { CommonService } from 'src/app/common/services/common.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, filter } from 'rxjs';
import { NavigationEnd } from '@angular/router';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private module_code: string = '';

    constructor(
        private Router: Router,
        private StorageMap: StorageMap,
        private AlertController: AlertController,
        private CommonService: CommonService,
        private AuthGatewayService: AuthGatewayService
    ) {
        this.initializeUserData();

        this.Router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            let currentRoute = this.Router.routerState.snapshot.root;
            while (currentRoute.firstChild) {
                currentRoute = currentRoute.firstChild;
            }
            this.module_code = currentRoute.data['module_code'];
        });
    }

    public initializeUserData = () => {
        this.StorageMap.watch('user').subscribe((user) => {
            this.userSubject.next(user);
        });
    }

    public getUser = (): any => {
        return this.userSubject.getValue();
    }

    public setUserData = (staff: any) => {
        const staffTimezone = staff.timezone || 'Asia/Manila';

        const user = {
            id: staff._id,
            email: staff.email,
            name: staff.name,
            position: staff.position,
            timelog_start: staff.timelog_start,
            status: staff.status,
            staff_timelog_id: staff.staff_timelog_id,
            timelog_id: staff.timelog_id,
            status_id: staff.status_id,
            department: staff.department.department_code,
            access_level: staff.access_level_data.access_code,
            module_codes: staff.access_level_data.module_codes,
            avatar: staff.staff_avatar,
            staff_timezone: staffTimezone,
            timezone_offset: `UTC ${moment.tz(staffTimezone).format('Z')}`,
        }

        this.StorageMap.set('user', user).subscribe(() => {});
    }

    public setUserTimelogData = (staff: any) => {
        this.StorageMap.get('user').subscribe((user: any) => {
            user.timelog_start = staff.timelog_start;
            user.status = staff.status;
            user.staff_timelog_id = staff.staff_timelog_id;
            user.timelog_id = staff.timelog_id;
            user.status_id = staff.status_id;
            this.StorageMap.set('user', user).subscribe(() => {});
        });
    }

    public logout = () => {
        this.StorageMap.clear().subscribe(() => {});
        this.AuthGatewayService.logout().subscribe(
            (res: any) => {
                if (!res.success) {
                    this.CommonService.createToast('Error logging out');
                    return;
                }

                this.Router.navigate(['login']);
            },
            (err: any) => {
                this.CommonService.createToast('Error logging out');
            }
        );
    }

    public relogin = async (res: any, error: any) => {
        this.CommonService.createToast(error);
        if (res.relogin) {
            const alert = await this.AlertController.create({
                header: 'Login',
                message: 'You have been automatically logged out',
                backdropDismiss: false,
                inputs: [
                    {
                        name: 'username',
                        type: 'text',
                        placeholder: 'Email',
                        attributes: {
                            autocomplete: 'username'
                        },
                    },
                    {
                        name: 'password',
                        type: 'password',
                        placeholder: 'Password',
                        attributes: {
                            autocomplete: 'current-password'
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            this.logout();
                        },
                    },
                    {
                        text: 'Login',
                        role: 'confirm',
                        handler: (data) => {
                            const reloginUser = {
                                email: data.username,
                                password: data.password,
                            };

                            this.AuthGatewayService.login(reloginUser).subscribe(
                                (loginRes: any) => {
                                    if (!loginRes.success) {
                                        this.relogin(
                                            res,
                                            loginRes.error
                                        );
                                        return;
                                    }

                                    this.setUserData(loginRes.staff);

                                    this.checkAuthorization();
                                },
                                (err: any) => {
                                    this.CommonService.createToast(
                                        'Error logging in user'
                                    );
                                    this.logout();
                                }
                            );
                        },
                    },
                ],
            });

            await alert.present();
        }
    }

    private checkAuthorization = () => {
        this.StorageMap.get('user').subscribe(
            (user: any) => {
                const module_code = this.getModuleCode();

                if (user?.module_codes?.includes(module_code) || module_code == '') {
                    return true;
                } else {
                    this.Router.navigate(['/dashboard']);
                    this.CommonService.createToast(
                        'You are not authorized to access this page'
                    );
                    return false;
                }
            }
        );
    }

    getModuleCode() {
        return this.module_code;
    }
}
