import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { AppModule } from 'src/app/app.module';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'app-staff-status-logs-modal',
    templateUrl: './staff-status-logs-modal.component.html',
    standalone: true,
    imports: [AppModule, IonicModule, NgxDatatableModule],
})
export class StaffStatusLogsModalComponent implements OnInit {
    public name: string = '';
    public timezone: string = '';
    public date: string = '';
    public statusLogs: any;
    public totalBioBreakHours: number = 0.00;
    public totalLunchBreakHours: number = 0.00;
    public maxBioBreakHours: number = 0.5;
    public maxLunchBreakHours: number = 1;
    public user: any;

    constructor(
        private ModalController: ModalController,
        private AuthService: AuthService,
    ) {
        this.user = this.AuthService.getUser();
    }

    ngOnInit() {
        const isBreakTimeChangedDate = this.checkBreakTimeChangedDate(this.date, this.timezone);
        
        if (isBreakTimeChangedDate) {
            this.maxBioBreakHours = 0.25;
            this.maxLunchBreakHours = 0.75;
        }
    }

    public closeStatusLogModal = () => {
        return this.ModalController.dismiss(null, 'cancel');
    };
    
    public checkBreakTimeChangedDate = (dateOfWork: string, staffTimeZone: string) => {
        const dateLoggedIn = moment.tz(dateOfWork, staffTimeZone).format('YYYY-MM-DD');
        const breakTimeChangedDate = moment.tz("2024-10-24", staffTimeZone).format('YYYY-MM-DD');
        return dateLoggedIn >= breakTimeChangedDate;
    }
}
